<template>
  <div>
    <Crumbs></Crumbs>
    <div
      class="mainList"
      v-loading="PageLoading"
      element-loading-text="拼命下载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-collapse v-model="screenNames">
        <el-collapse-item name="1">
          <template slot="title" v-if="screenNames.indexOf('1') == -1">
            <span @keydown.stop="" @keyup.stop="" @click.stop=""
              >项目名称：
              <el-autocomplete
                style="width: 500px; margin-right: 10px"
                class="ipt_width project_width"
                v-model="parameter.projectName"
                :fetch-suggestions="projectQuerySearch"
                placeholder="请输入或选择项目名"
                @select="onProkectNameSelect"
                :popper-append-to-body="false"
              >
              </el-autocomplete>
            </span>
            <el-button type="primary" icon="el-icon-search" @click.stop="searchForFn"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click.stop="handleReset"
              >清空</el-button
            >
          </template>
          <div class="mainList_operation clearfloat">
            <div class="mainList_operation_inline_block">
              <div class="mainList_operation_search">
                <div style="display: flex">
                  <div>
                    <div class="search_center">
                      <span
                        >部门：
                        <DeptCascader
                          :placeholder="'请选择部门'"
                          :deptList="deptList"
                          v-model="parameter.deptId"
                        />
                      </span>
                    </div>
                    <div class="search_center">
                      <span
                        >项目名称：
                        <el-autocomplete
                          class="ipt_width project_width"
                          v-model="parameter.projectName"
                          :fetch-suggestions="projectQuerySearch"
                          placeholder="请输入或选择项目名"
                          @select="onProkectNameSelect"
                          :popper-append-to-body="false"
                        >
                        </el-autocomplete>
                      </span>
                    </div>
                    <div class="search_center">
                      <span
                        >项目状态：
                        <el-select
                          class="ipt_width tags-select-input"
                          clearable
                          multiple
                          collapse-tags
                          v-model="parameter.projectStatusList"
                          placeholder="请选择项目状态"
                        >
                          <el-option
                            v-for="item in projectStatus"
                            :label="item.dictName"
                            :value="item.dictVal"
                            :key="item.dictVal"
                          ></el-option>
                        </el-select>
                      </span>
                    </div>
                    <div class="search_center">
                      <span
                        >项目分类：
                        <el-select
                          class="ipt_width tags-select-input"
                          clearable
                          multiple
                          collapse-tags
                          v-model="parameter.projectCategoryList"
                          placeholder="请选择项目分类"
                        >
                          <el-option
                            v-for="item in projectCategorys"
                            :label="item.dictName"
                            :value="item.dictVal"
                            :key="item.dictVal"
                          ></el-option>
                        </el-select>
                      </span>
                    </div>
                    <div class="search_center">
                      <span
                        >项目审批状态：
                        <el-select
                          class="ipt_width tags-select-input"
                          v-model="parameter.statusList"
                          placeholder="请选择项目审批状态"
                          multiple
                          collapse-tags
                          clearable
                        >
                          <el-option
                            v-for="item in StautsType"
                            :label="item.dictName"
                            :value="item.dictVal"
                            :key="item.dictVal"
                          ></el-option>
                        </el-select>
                      </span>
                    </div>
                    <!-- <div class="search_center">
                      <span
                        >项目性质：
                        <el-select
                          class="ipt_width"
                          v-model="parameter.projectNature"
                          placeholder="请选择项目性质"
                          filterable
                          clearable>
                          <el-option
                            v-for="item in projectNatureInfo"
                            :label="item.dictName"
                            :value="item.dictVal"
                            :key="item.id"></el-option>
                        </el-select>
                      </span>
                    </div>
                    <div class="search_center">
                      <span
                        >项目来源：
                        <el-select
                          class="ipt_width"
                          v-model="parameter.projectSource"
                          placeholder="请选择项目来源"
                          filterable
                          clearable>
                          <el-option
                            v-for="item in dictData.projectSource"
                            :label="item.dictName"
                            :value="item.dictVal"
                            :key="item.dictVal"></el-option>
                        </el-select>
                      </span>
                    </div> -->
                    <div class="search_center">
                      <span
                        >是否已签署：
                        <el-select
                          class="ipt_width"
                          v-model="parameter.agreementExist"
                          placeholder="请选择"
                          filterable
                          clearable
                        >
                          <el-option
                            v-for="item in dictData.agreementExist"
                            :label="item.dictName"
                            :value="item.dictVal"
                            :key="item.dictName"
                          ></el-option>
                        </el-select>
                      </span>
                    </div>
                    <div class="search_center">
                      <span
                        >业务种类：
                        <el-select
                          class="ipt_width tags-select-input"
                          v-model="parameter.businessLinesList"
                          placeholder="请选择业务种类"
                          filterable
                          clearable
                          multiple
                          collapse-tags
                        >
                          <el-option
                            v-for="item in dictData.businessLines"
                            :label="item.dictName"
                            :value="item.dictVal"
                            :key="item.dictVal"
                          ></el-option>
                        </el-select>
                      </span>
                    </div>

                    <!-- <div class="search_center">
              <span
                >项目类型：
                <el-select
                  class="ipt_width"
                  v-model="parameter.projectType"
                  placeholder="请选择项目类型"
                  clearable>
                  <el-option
                    v-for="item in ProjectType"
                    :label="item.dictName"
                    :value="item.dictVal"
                    :key="item.dictVal"></el-option>
                </el-select>
              </span>
            </div> -->

                    <div class="search_center">
                      <span
                        >项目承接公司：
                        <el-select
                          class="ipt_width tags-select-input"
                          v-model="parameter.companyTypeList"
                          placeholder="请选择项目承接公司"
                          filterable
                          clearable
                          multiple
                          collapse-tags
                        >
                          <el-option
                            v-for="item in dictData.companyType"
                            :label="item.dictName"
                            :value="item.dictVal"
                            :key="item.id"
                          ></el-option>
                        </el-select>
                      </span>
                    </div>
                    <div class="search_center">
                      <span
                        >业主单位：
                        <el-select
                          class="ipt_width tags-select-input"
                          v-model="parameter.customersUnitList"
                          placeholder="请选择业主单位"
                          filterable
                          clearable
                          multiple
                          collapse-tags
                        >
                          <el-option
                            v-for="item in customersUnit"
                            :label="item"
                            :value="item"
                            :key="item"
                          ></el-option>
                        </el-select>
                      </span>
                    </div>
                    <div class="search_center">
                      <span
                        >项目经理：
                        <el-select
                          class="ipt_width tags-select-input"
                          filterable
                          v-model="parameter.deptClassifyManagerIdList"
                          placeholder="请选择项目经理"
                          clearable
                          multiple
                          collapse-tags
                        >
                          <el-option
                            v-for="item in deptClassifyManagerNameList"
                            :label="item.userName"
                            :value="item.id"
                            :key="item.id"
                          ></el-option>
                        </el-select>
                      </span>
                    </div>
                    <div class="search_center">
                      <span>实际开始时间：</span>
                      <el-date-picker
                        class="date_width"
                        v-model="createdDate"
                        :pickerOptions="pickerOptions"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="实际日期（始）"
                        end-placeholder="实际日期（末）"
                      >
                      </el-date-picker>
                    </div>
                    <div
                      class="search_center"
                      v-if="permission(['PROJECT_DISPOASABLE_EXPENSES_EXPORT'])"
                    >
                      <span>创建时间：</span>
                      <el-date-picker
                        v-model="parameter.startCreateDate"
                        type="date"
                        placeholder="选择日期"
                      >
                      </el-date-picker>
                    </div>
                    <el-button type="primary" icon="el-icon-search" @click="searchForFn"
                      >搜索</el-button
                    >
                    <el-button type="warning" icon="el-icon-refresh" @click="handleReset"
                      >清空</el-button
                    >
                  </div>

                  <div>
                    <el-radio-group v-model="ProjectNature">
                      <el-radio
                        border
                        size="medium"
                        style="width: 100%; margin-right: 0px"
                        label="ALL"
                        >全部</el-radio
                      >
                      <div style="display: flex">
                        <span
                          style="border: 1px solid #dcdfe6; padding: 8px 0px 0px 5px"
                          v-if="dictProjectList[0] && dictProjectList[0][0]"
                        >
                          <el-radio size="medium" :label="dictProjectList[0][0].produceVal">{{
                            dictProjectList[0][0].produceName
                          }}</el-radio>
                          <div style="padding-left: 10px">
                            <el-checkbox-group v-model="produceList">
                              <template v-for="item in dictProjectList[0]">
                                <div :key="item.id">
                                  <el-checkbox :label="item.dictVal">{{
                                    item.dictName
                                  }}</el-checkbox>
                                </div>
                              </template>
                            </el-checkbox-group>
                          </div>
                        </span>
                        <span
                          style="border: 1px solid #dcdfe6; padding: 8px 0px 0px 5px"
                          v-if="dictProjectList[1] && dictProjectList[1][0]"
                        >
                          <el-radio size="medium" :label="dictProjectList[1][0].cooperateVal">{{
                            dictProjectList[1][0].cooperateName
                          }}</el-radio>
                          <div style="padding-left: 10px">
                            <el-checkbox-group v-model="cooperateList">
                              <template v-for="item in dictProjectList[1]">
                                <div :key="item.id">
                                  <el-checkbox :label="item.dictVal">{{
                                    item.dictName
                                  }}</el-checkbox>
                                </div>
                              </template>
                            </el-checkbox-group>
                          </div>
                        </span>
                      </div>
                    </el-radio-group>
                  </div>
                </div>
              </div>
            </div>
            <div class="fr">
              <el-button
                type="primary"
                v-if="permission(['PROJECT_EXPORT'])"
                icon="el-icon-printer"
                @click="exportFn"
                >导出</el-button
              >
              <el-button
                type="success"
                style="width: 140px"
                v-if="permission(['SYNCHRONIZATION_MOENY'])"
                icon="el-icon-refresh"
                @click="onSynchronizationMoneyFn"
                >同步项目金额</el-button
              >
              <el-button
                type="primary"
                v-if="permission(['PROJECT_DISPOASABLE_EXPENSES_EXPORT'])"
                icon="el-icon-printer"
                style="width: 195px"
                @click="DisposableExpensesExportFn"
                >导出项目可支配资金</el-button
              >
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
      <!-- PROJECT_STATISTICAL_AMOUNT -->
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-descriptions
            class="margin-top"
            :column="1"
            border
            v-if="permission(['PROJECT_STATISTICAL_AMOUNT']) && isAmount"
          >
            <el-descriptions-item label-class-name="labelClassName">
              <template slot="label">
                <span style="color: #000; font-size: 16px">项目实际金额统计</span>
              </template>
              <span style="color: red">{{
                projectAmount > 0 ? projectAmount : 0 | applyAmount
              }}</span>
            </el-descriptions-item>
          </el-descriptions>
          <el-table
            ref="multipleTable"
            border
            :data="tableData"
            tooltip-effect="dark"
            :height="tableHeight"
            row-key="id"
            lazy
            :load="load"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="sortChange"
            v-loading="loading"
          >
            <el-table-column
              align="center"
              prop="projectName"
              fixed="left"
              label="项目名称"
              :show-overflow-tooltip="false"
              min-width="320"
            ></el-table-column>

            <!-- <el-table-column prop="projectType" align="center" label="项目类型">
              <template slot-scope="scope">
                {{ scope.row.projectType | dict(dictData.projectType) }}
              </template>
            </el-table-column> -->
            <el-table-column prop="" width="120" align="center" label="项目性质">
              <template slot-scope="scope">
                {{ scope.row.projectSource | dict(dictData.projectNature) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="projectSource"
              sortable
              width="120"
              align="center"
              label="项目来源"
            >
              <template slot-scope="scope">
                {{ scope.row.projectSource | dict(dictData.projectSource) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="businessLines"
              sortable
              width="120"
              align="center"
              label="业务种类"
            >
              <template slot-scope="scope">
                {{ scope.row.businessLines | dict(dictData.businessLines) }}
              </template>
            </el-table-column>
            <el-table-column align="center" width="180" prop="status" label="项目审批状态">
              <template slot-scope="scope">
                {{ scope.row.status | dict(dictData.projectStatus) }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              sortable
              width="130"
              prop="companyType"
              label="项目承接公司"
            >
              <template slot-scope="scope">
                {{ scope.row.companyType | dict(dictData.companyType) }}
              </template>
            </el-table-column>

            <!-- <el-table-column
              align="center"
              prop="agreementName"
              label="合同名称"
            ></el-table-column> -->
            <el-table-column
              align="center"
              min-width="220"
              :show-overflow-tooltip="false"
              prop="customersUnit"
              label="业主单位"
            ></el-table-column>
            <el-table-column
              align="center"
              width="160"
              :show-overflow-tooltip="false"
              prop="countAgreement"
              label="是否已签署合同"
            >
              <template slot-scope="scope">
                {{ scope.row.countAgreement > 0 ? '已签署' : '未签署' }}
                <!-- <el-tag v-if="scope.row.countAgreement > 0" type="" effect="dark"> 已签署 </el-tag>
                <el-tag v-else type="danger" effect="dark"> 未签署 </el-tag> -->
              </template>
            </el-table-column>
            <el-table-column align="center" width="180" prop="projectStatus" label="项目状态">
              <template slot-scope="scope">
                {{ scope.row.projectStatus | dict(dictData.projectStatusList) }}
              </template>
            </el-table-column>
            <el-table-column align="center" width="180" prop="projectCategory" label="项目分类">
              <template slot-scope="scope">
                {{ scope.row.projectCategory | dict(dictData.projectCategorysList) }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="130"
              sortable
              :show-overflow-tooltip="false"
              prop="predictContractAmount"
              label="我方合同金额"
            >
              <template slot-scope="scope">
                {{ scope.row.predictContractAmount | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="130"
              sortable
              :show-overflow-tooltip="false"
              prop="actualContractAmount"
              label="实际合同金额"
            >
              <template slot-scope="scope">
                {{ scope.row.actualContractAmount | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="130"
              sortable
              :show-overflow-tooltip="false"
              prop="actualAmount"
              label="项目实际金额"
            >
              <template slot-scope="scope">
                {{ scope.row.actualAmount | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="130"
              sortable
              :show-overflow-tooltip="false"
              prop="invoiceAmount"
              label="已开票金额"
            >
              <template slot-scope="scope">
                {{ scope.row.invoiceAmount | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="130"
              sortable
              :show-overflow-tooltip="false"
              prop="proceedsAmount"
              label="已收款金额"
            >
              <template slot-scope="scope">
                {{ scope.row.proceedsAmount | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="130"
              prop="actualPredictStartDate"
              sortable
              label="	实际开始时间"
            >
              <template slot-scope="scope">
                {{ scope.row.actualPredictStartDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="130"
              prop="projectPredictStartDate"
              label="预计开始时间"
              sortable
            >
              <template slot-scope="scope">
                {{ scope.row.projectPredictStartDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="projectPredictEndDate"
              label="预计结束时间"
              width="130"
              sortable
            >
              <template slot-scope="scope">
                {{ scope.row.projectPredictEndDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="deptClassifyName" width="120" label="部门分类">
            </el-table-column>
            <el-table-column
              align="center"
              prop="deptClassifyManagerName"
              width="100"
              label="项目经理"
            >
            </el-table-column>
            <el-table-column align="center" prop="principalUserName" width="100" label="商务负责人">
            </el-table-column>
            <template v-if="permission(['EXPENSESVIEW'])">
              <el-table-column label="操作" fixed="right" align="center" width="160">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    class="text_Edit_Bgc"
                    size="small"
                    v-if="scope.row.status > 1"
                    @click="edit(scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    class="text_Details_Bgc"
                    @click.stop="detail(scope.row)"
                    >详情</el-button
                  >

                  <el-popover
                    placement="bottom"
                    width="200"
                    trigger="hover"
                    v-if="scope.row && scope.row.isViewMonitoring && permission(['EXPENSESVIEW'])"
                  >
                    <el-button
                      type="text"
                      v-if="
                        scope.row.operatingButton &&
                        scope.row.operatingButton.some(r => {
                          return r == 'EXPENSES'
                        })
                      "
                      size="small"
                      class="text_RESET_PASSWORD_Bgc"
                      @click.stop="CostFn(scope.row)"
                      >费用</el-button
                    >
                    <el-button
                      type="text"
                      v-if="
                        scope.row.operatingButton &&
                        scope.row.operatingButton.some(r => {
                          return r == 'DISPOSABLE_EXPENSES'
                        })
                      "
                      size="small"
                      class="text_Miantan_Bgc"
                      @click.stop="isCostShowFn(scope.row)"
                      >结算</el-button
                    >
                    <el-button
                      type="text"
                      v-if="
                        scope.row.operatingButton &&
                        scope.row.operatingButton.some(r => {
                          return r == 'DISPOSABLE_EXPENSES'
                        })
                      "
                      size="small"
                      class="text_Edit_Bgc"
                      @click.stop="isisProjectCostFn(scope.row)"
                      >结算调整</el-button
                    >
                    <span slot="reference" class="more">更多</span>
                  </el-popover>
                </template>
              </el-table-column>
            </template>
            <template v-else>
              <el-table-column label="操作" fixed="right" align="center" width="70">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    class="text_Details_Bgc"
                    @click.stop="edit(scope.row)"
                    >详情</el-button
                  >
                </template>
              </el-table-column>
            </template>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <Edit
      :isShow.sync="showEditDialog"
      :mode="mode"
      :StautsType="StautsType"
      :ProjectType="ProjectType"
      :options="options"
    ></Edit>

    <EditDialog
      width="70%"
      @closeFn="isCostShow = false"
      :isReturn="true"
      v-if="isCostShow"
      :isShow.sync="isCostShow"
    >
      <div slot="title" class="title">项目可支配资金</div>
      <div slot="content" class="content" style="min-height: 400px">
        <Cost :isDisable="true" :options="costOptions"></Cost>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="isCostShow = false">返回</el-button>
      </div>
    </EditDialog>

    <EditDialog
      width="80%"
      :isReturn="true"
      v-if="costDialog_show"
      @closeFn="costDialog_show = false"
      :isShow.sync="costDialog_show"
    >
      <div slot="title" class="title">项目报销合计信息</div>
      <div slot="content" class="content" style="min-height: 400px">
        <el-card>
          <div class="cost-dialog-title">
            <el-descriptions border size="medium"> </el-descriptions>
          </div>
          <div class="search_cost_title">
            <div class="search_center">
              <span>编号：</span>
              <el-input
                v-model="leftParams.expenseNumber"
                @change="$forceUpdate()"
                style="width: 180px"
                placeholder="请输入编号"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center" style="margin-left: 8px">
              <span>姓名：</span>
              <el-input
                v-model="leftParams.userName"
                @change="$forceUpdate()"
                style="width: 120px"
                placeholder="请输入姓名"
                clearable
              >
              </el-input>
            </div>

            <div class="search_center" style="margin-left: 8px">
              <span>申请时间：</span>
              <el-date-picker
                class="date_width"
                v-model="createdDate"
                :pickerOptions="pickerOptions"
                type="daterange"
                range-separator="至"
                start-placeholder="申请日期（始）"
                end-placeholder="申请日期（末）"
              >
              </el-date-picker>
            </div>

            <el-button
              style="margin-left: 8px"
              type="primary"
              size="small"
              icon="el-icon-search"
              @click="handleLeftCurrentChange(1)"
              >搜索</el-button
            >
            <el-button
              type="warning"
              size="small"
              icon="el-icon-refresh"
              @click="costDialog_dataHandleReset"
              >清空</el-button
            >
          </div>

          <el-tabs v-model="activeCost">
            <el-tab-pane label="报销" name="COST"></el-tab-pane>
            <el-tab-pane label="付款申请" name="PAYMENT"></el-tab-pane>
            <el-tab-pane label="库管/厨师登记" name="EXPENDITURE"></el-tab-pane>
          </el-tabs>
          <el-table
            :data="costDialog_data.leftTableData"
            border
            ref="leftTableRef"
            height="350"
            :key="tableIndex"
            style="width: 100%"
            v-loading="leftCostLoading"
            :highlight-current-row="false"
          >
            <el-table-column type="index" align="center" label="序号" width="50"> </el-table-column>
            <el-table-column prop="expenseNumber" min-width="160" align="center" label="编号">
            </el-table-column>
            <el-table-column
              prop="createName"
              v-if="activeCost != 'EXPENDITURE'"
              min-width="100"
              align="center"
              label="姓名"
            >
            </el-table-column>
            <el-table-column
              prop="applyName"
              v-if="activeCost === 'EXPENDITURE'"
              min-width="100"
              align="center"
              label="确认人"
            >
            </el-table-column>
            <el-table-column align="center" min-width="130" prop="companyType" label="费用所属单位">
              <template slot-scope="scope">
                {{ scope.row.companyType | dict(companyTypeList) }}
              </template></el-table-column
            >
            <el-table-column
              align="center"
              v-if="activeCost === 'EXPENDITURE'"
              min-width="140"
              prop="registrationType"
              label="登记类型"
            >
              <template slot-scope="scope">
                {{ scope.row.registrationType | dict(registrationTypeList) }}
              </template></el-table-column
            >
            <el-table-column prop="sumCost" align="center" min-width="100" label="报销费用">
              <template slot-scope="scope">
                {{ scope.row.sumCost | thousands }}
              </template>
            </el-table-column>
            <el-table-column prop="taskDate" min-width="150" align="center" label="申请日期">
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column prop="statusName" min-width="110" align="center" label="流程进度">
            </el-table-column>
            <el-table-column width="100" align="center" label="操作">
              <template slot-scope="scope">
                <el-button type="primary" size="mini" @click="showCostDetail(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div style="text-align: right; margin-top: 10px">
            <el-pagination
              @size-change="handleLeftSizeChange"
              @current-change="handleLeftCurrentChange"
              :current-page="leftParams.pageNow"
              :page-sizes="[5, 10, 50, 100]"
              :page-size="10"
              layout="total, sizes, prev, pager, next, jumper"
              :total="leftParams.total"
            >
            </el-pagination>
          </div>
        </el-card>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="costDialog_show = false">返回</el-button>
      </div>
    </EditDialog>

    <ProjectPerformanceDetailDialog
      :type="activeCost"
      v-if="costDetailDialog_show"
      :costDetailDialog_show.sync="costDetailDialog_show"
      :options="costDialog_data.costDetail"
      @closeDialog="costDetailDialog_show = false"
    ></ProjectPerformanceDetailDialog>

    <!-- 绩效调整 -->
    <PerformanceAdjustment
      :options="projectCostInfo"
      v-if="isProjectCost"
      :isProjectCost.sync="isProjectCost"
    />
  </div>
</template>
<script>
import { initTreeData } from '@/util/common'
import { mapState } from 'vuex'
export default {
  components: {
    Edit: () => import('../Edit.vue'),
    Cost: () => import('../Cost.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Crumbs: () => import('@/components/Crumbs.vue'),
    ProjectPerformanceDetailDialog: () => import('@/components/ProjectPerformanceDetailDialog.vue'),
    PerformanceAdjustment: () => import('../performance-adjustment.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        startActualPredictStartDate: null,
        endActualPredictStartDate: null,
        deptClassifyManagerIdList: null,
        projectName: null,
        businessLinesList: null,
        projectSourceList: [],
        companyTypeList: null,
        customersUnit: null,
        deptId: null,
        statusList: [],
        agreementExist: null,
        startCreateDate: null,
        projectStatusList: null,
        projectCategoryList: null,
      },
      pageSize: 0,
      dictData: {
        projectType: [],
        projectSource: [],
        projectStatus: [],
        projectStatusList: [],
        projectCategorysList: [],
        businessLines: [],
        projectNature: [],
        companyType: [],
        agreementExist: [
          {
            dictName: '已签署',
            dictVal: true,
          },
          {
            dictName: '未签署',
            dictVal: false,
          },
        ],
      },
      projectNatureInfo: [],
      tableData: [],
      deptList: [],
      loading: false,
      PageLoading: false,
      options: {},
      showEditDialog: false,
      ProjectType: [],
      StautsType: [],
      projectStatus: [],
      projectCategorys: [],
      customersUnit: [],
      projectList: [],
      createdDate: [],
      deptClassifyManagerNameList: [],
      projectAmount: 0,
      isAmount: false,
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
      screenNames: [],
      produceList: [],
      cooperateList: [],
      ProjectNature: 'ALL',
      isCostShow: false,
      costDialog_show: false, //报销弹窗
      isProjectCostLoading: false, //报销按钮
      leftCostLoading: false, //报销弹窗表格
      costDetailDialog_show: false, //报销详情弹窗
      leftParams: {
        //报销弹窗请求参数
        pageSize: 10,
        pageNow: 1,
        total: 0,
        userName: null,
        expenseNumber: null,
      },
      costDialog_data: {
        //报销
        leftTableData: [],
        costDetail: {},
      },
      activeCost: 'COST',
      registrationTypeList: [],
      companyTypeList: [],
      costOptions: {},
      tableIndex: 0,
      //绩效调整
      projectCostInfo: {},
      isProjectCost: false,
      mode: 'view',
    }
  },
  watch: {
    costDialog_show: {
      // deep: true,
      handler: function (val) {
        if (!val) {
          this.costReturn()
        }
      },
    },
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },

    activeCost: {
      // deep: true,
      handler: function (val) {
        this.tableIndex++
        this.leftParams.pageNow = 1
        if (val === 'COST') {
          this.projectCostFn(this.leftParams)
        } else if (val === 'PAYMENT') {
          this.paymentFn(this.leftParams)
        } else if (val === 'EXPENDITURE') {
          this.registerFn(this.leftParams)
        }
      },
    },
    $route: {
      deep: true,
      immediate: true,
      handler: function (val) {
        if (val.query && val.query.data) {
          const item = JSON.parse(val.query.data)
          if (item) {
            this.edit(item)
            // 获取当前页面的 URL
            let currentUrl = window.location.href
            // 清除查询参数部分
            let cleanUrl = currentUrl.split('?')[0]
            // 修改浏览器的历史记录，但不会触发页面刷新
            window.history.replaceState({}, document.title, cleanUrl)
          }
        }
      },
    },
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),

    tableHeight() {
      return this.permission(['PROJECT_STATISTICAL_AMOUNT']) && this.isAmount
        ? 'calc(100% - 122px)'
        : 'calc(100% - 72px)'
    },
    dictProjectList() {
      let arr = [[], []]
      this.projectNatureInfo.forEach(v => {
        this.dictData.projectSource.forEach(item => {
          if (v.dictVal.indexOf(item.dictVal) != -1 && v.dictName == '生产项目') {
            arr[0].push({
              id: item.id,
              produceName: v.dictName,
              produceVal: v.dictVal,
              dictVal: item.dictVal,
              dictName: item.dictName,
            })
          } else if (v.dictVal.indexOf(item.dictVal) != -1 && v.dictName == '合作项目') {
            arr[1].push({
              id: item.id,
              cooperateName: v.dictName,
              cooperateVal: v.dictVal,
              dictVal: item.dictVal,
              dictName: item.dictName,
            })
          }
        })
      })
      return arr
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()
    this.getProject()
    this.getType()
  },
  filters: {
    applyAmount(n) {
      if (n == 0) {
        return n
      } else if (!n) {
        return
      } else {
        return Number(n).toLocaleString()
      }
    },
    thousands(a) {
      if (!a) {
        return a
      } else {
        a = String(a).replace(/,/g, '')
        a = Number(a).toLocaleString()

        return a
      }
    },
  },
  provide() {
    return {
      refresh: this.refresh,
    }
  },
  methods: {
    //绩效调整
    isisProjectCostFn(row) {
      this.projectCostInfo = row.deepClone()
      this.$store.commit({
        type: 'project/SET_PROJECTINFORMATION',
        projectInformation: {
          projectName: row.projectName,
          managerName: row.deptClassifyManagerName,
          theirDeptName: row.theirDeptName,
          businessLines: row.businessLines,
          projectSource: row.projectSource,
        },
      })
      this.isProjectCost = true
    },
    // 报销详细弹窗
    showCostDetail(row) {
      this.costDialog_data.costDetail = row.deepClone()
      this.costDetailDialog_show = true
    },
    // 报销返回
    costReturn() {
      this.leftParams.userName = null
      this.leftParams.startDate = null
      this.leftParams.endDate = null
      this.leftParams.expenseNumber = null
      this.createdDate = []
      this.activeCost = 'COST'
    },

    // 报销弹窗-清空
    costDialog_dataHandleReset() {
      this.leftParams.userName = null
      this.leftParams.startDate = null
      this.leftParams.endDate = null
      this.leftParams.expenseNumber = null
      this.createdDate = []
      if (this.activeCost === 'COST') {
        this.projectCostFn(this.leftParams)
      } else if (this.activeCost === 'PAYMENT') {
        this.paymentFn(this.leftParams)
      } else if (this.activeCost === 'EXPENDITURE') {
        this.registerFn(this.leftParams)
      }
    },
    // 报销弹窗-搜索
    handleLeftCurrentChange(val) {
      this.leftParams.pageNow = val
      if (this.createdDate && this.createdDate != []) {
        this.leftParams.startDate = new Date(this.createdDate[0]).getTime()
        this.leftParams.endDate = new Date(this.createdDate[1]).getTime()
      } else {
        this.leftParams.startDate = null
        this.leftParams.endDate = null
      }
      if (this.activeCost === 'COST') {
        this.projectCostFn(this.leftParams)
      } else if (this.activeCost === 'PAYMENT') {
        this.paymentFn(this.leftParams)
      } else if (this.activeCost === 'EXPENDITURE') {
        this.registerFn(this.leftParams)
      }
    },
    handleLeftSizeChange(val) {
      this.leftParams.pageSize = val
      this.leftParams.pageNow = 1
      if (this.activeCost === 'COST') {
        this.projectCostFn(this.leftParams)
      } else if (this.activeCost === 'PAYMENT') {
        this.paymentFn(this.leftParams)
      } else if (this.activeCost === 'EXPENDITURE') {
        this.registerFn(this.leftParams)
      }
    },
    // 付款申请
    paymentFn(pageSize) {
      this.leftCostLoading = true
      let obj = {
        pageSize: pageSize.pageSize,
        pageNow: pageSize.pageNow,
        userName: pageSize.userName,
        startDate: pageSize.startDate,
        endDate: pageSize.endDate,
        expenseNumber: pageSize.expenseNumber,
        projectId: this.costOptions.id,
        notStatusList: [0],
      }
      this.$api.payment
        .projectPaymentApplicationAllList(obj)
        .then(res => {
          if (res.data == []) {
            this.costDialog_data.leftTableData = res.data
          } else {
            this.costDialog_data.leftTableData = res.data
              ? res.data.records
                ? res.data.records
                : []
              : []
            this.leftParams.total = res.data ? (res.data.total ? res.data.total : 0) : 0
          }
          this.leftCostLoading = false
        })
        .catch(err => {
          console.log(err)
          this.leftCostLoading = false
        })
    },
    // 库管/厨师登记
    registerFn(pageSize) {
      this.leftCostLoading = true
      let obj = {
        expenseType: 'PROJECT',
        registrationTypeList: ['KITCHEN', 'INVENTORY_KEEPER'],
        pageSize: pageSize.pageSize,
        pageNow: pageSize.pageNow,
        userName: pageSize.userName,
        startDate: pageSize.startDate,
        endDate: pageSize.endDate,
        expenseNumber: pageSize.expenseNumber,
        expenseBelongId: this.costOptions.id,
        notStatusList: [0],
      }
      this.$api.register
        .registrationExpenditureAllList(obj)
        .then(res => {
          if (res.data == []) {
            this.costDialog_data.leftTableData = res.data
          } else {
            this.costDialog_data.leftTableData = res.data
              ? res.data.records
                ? res.data.records
                : []
              : []
            this.leftParams.total = res.data ? (res.data.total ? res.data.total : 0) : 0
          }
          this.leftCostLoading = false
        })
        .catch(err => {
          this.leftCostLoading = false
          console.log(err)
        })
    },
    // 报销
    projectCostFn(pageSize) {
      this.leftCostLoading = true
      let obj = {
        pageSize: pageSize.pageSize,
        pageNow: pageSize.pageNow,
        userName: pageSize.userName,
        startDate: pageSize.startDate,
        endDate: pageSize.endDate,
        expenseNumber: pageSize.expenseNumber,
        projectId: this.costOptions.id,
        notStatusList: [0],
      }
      this.$api.projectCost
        .projectExpenseAllList(obj)
        .then(res => {
          if (res.data == []) {
            this.costDialog_data.leftTableData = res.data
          } else {
            this.costDialog_data.leftTableData = res.data
              ? res.data.records
                ? res.data.records
                : []
              : []
            this.leftParams.total = res.data ? (res.data.total ? res.data.total : 0) : 0
          }
          this.leftCostLoading = false
        })
        .catch(err => {
          console.log(err)
          this.leftCostLoading = false
        })
    },
    CostFn(row) {
      this.costOptions = row.deepClone()
      this.isProjectCostLoading = true
      if (this.createdDate && this.createdDate != []) {
        this.leftParams.startDate = new Date(this.createdDate[0]).getTime()
        this.leftParams.endDate = new Date(this.createdDate[1]).getTime()
      } else {
        this.leftParams.startDate = null
        this.leftParams.endDate = null
      }
      this.projectCostFn(this.leftParams)
      this.costDialog_show = true
      this.isProjectCostLoading = false
    },
    isCostShowFn(row) {
      this.costOptions = row.deepClone()
      this.$store.commit({
        type: 'project/SET_PROJECTINFORMATION',
        projectInformation: {
          projectName: row.projectName,
          managerName: row.deptClassifyManagerName,
          theirDeptName: row.theirDeptName,
          businessLines: row.businessLines,
          projectSource: row.projectSource,
        },
      })
      this.$api.project
        .selectAdjustmentDetailByProjectId(row.id)
        .then(res => {
          this.$store.commit({
            type: 'project/SET_PROJECTDISPOSABLEEXPENSES',
            projectDisposableExpenses: res.data.newData,
          })
        })
        .catch(err => {
          console.log(err)
        })
      this.isCostShow = true
    },
    projectQuerySearch(queryString, cb) {
      let projectList = this.projectList.map(item => {
        return { value: item.projectName }
      })
      let results = queryString ? this.projectCreateFilter(queryString) : projectList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    projectCreateFilter(queryString) {
      let results = this.projectList.map(item => {
        return { value: item.projectName }
      })
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },
    onProkectNameSelect(e) {
      if (e) {
        this.parameter.projectName = e.value
      }
      this.$forceUpdate()
    },
    async searchForFn() {
      if (this.permission(['PROJECT_STATISTICAL_AMOUNT'])) {
        if (this.createdDate && this.createdDate != []) {
          this.parameter.startActualPredictStartDate = new Date(this.createdDate[0]).getTime()
          this.parameter.endActualPredictStartDate = new Date(this.createdDate[1]).getTime()
        } else if (this.createdDate == []) {
          this.parameter.startActualPredictStartDate = null
          this.parameter.endActualPredictStartDate = null
        }
        this.isAmount = true
        if (this.ProjectNature != 'ALL') {
          let arr = [...this.produceList, ...this.cooperateList]
          this.ProjectNature.forEach(v => {
            if (arr.indexOf(v) == -1) {
              arr.push(v)
            }
          })
          this.parameter.projectSourceList = [...arr]
        } else {
          this.parameter.projectSourceList = [...this.produceList, ...this.cooperateList]
        }

        const res = await this.$api.project.listProjectSumAmount(this.parameter)

        this.projectAmount = res.data ? res.data : 0
      }
      this.pageChangeHandler(1)
    },
    async handleReset() {
      this.isAmount = true
      this.parameter.projectName = null
      this.parameter.statusList = []
      this.createdDate = []
      this.parameter.deptClassifyManagerIdList = null
      this.parameter.startActualPredictStartDate = null
      this.parameter.endActualPredictStartDate = null
      // this.parameter.projectType = null
      this.parameter.customersUnit = null
      this.parameter.deptId = null
      this.parameter.businessLinesList = null
      this.parameter.projectSourceList = []
      this.cooperateList = []
      this.produceList = []
      this.parameter.companyTypeList = null
      this.parameter.agreementExist = null
      this.parameter.startCreateDate = null
      this.parameter.projectStatusList = null
      this.parameter.projectCategoryList = null
      this.parameter.pageNow = 1
      this.ProjectNature = 'ALL'
      const res = await this.$api.project.listProjectSumAmount(this.parameter)
      this.projectAmount = res.data ? res.data : 0
      this.getData()
    },
    DisposableExpensesExportFn() {
      this.PageLoading = true
      const obj = {
        startCreateDate: this.parameter.startCreateDate,
        startActualPredictStartDate: this.parameter.startActualPredictStartDate,
        endActualPredictStartDate: this.parameter.endActualPredictStartDate,
      }
      this.$api.project
        .projectDisposableExpensesExport(obj)
        .then(res => {
          var blob = new Blob([res], { type: 'application/vnd.ms-excel' })

          var url = URL.createObjectURL(blob)

          var exportLink = document.createElement('a')

          exportLink.setAttribute('download', '项目可支配资金.xlsx')

          exportLink.href = url

          document.body.appendChild(exportLink)

          exportLink.click()
          document.body.removeChild(exportLink) //下载完成移除元素
          URL.revokeObjectURL(exportLink.href) //释放掉blob对象
          this.$message({
            message: '导出成功!',
            type: 'success',
          })
          this.PageLoading = false
        })
        .catch(err => {
          console.log(err)
          this.PageLoading = false
        })
    },
    exportFn() {
      this.PageLoading = true
      this.$api.project
        .projectExport(this.parameter)
        .then(res => {
          var blob = new Blob([res], { type: 'application/vnd.ms-excel' })

          var url = URL.createObjectURL(blob)

          var exportLink = document.createElement('a')

          exportLink.setAttribute('download', '项目信息.xlsx')

          exportLink.href = url

          document.body.appendChild(exportLink)

          exportLink.click()
          document.body.removeChild(exportLink) //下载完成移除元素
          URL.revokeObjectURL(exportLink.href) //释放掉blob对象
          this.$message({
            message: '导出成功!',
            type: 'success',
          })
          this.PageLoading = false
        })
        .catch(err => {
          console.log(err)
          this.PageLoading = false
        })
    },
    onSynchronizationMoneyFn() {
      this.PageLoading = true
      this.$api.project
        .synchronizationMoney()
        .then(res => {
          this.$message({
            message: '同步成功!',
            type: 'success',
          })
          this.PageLoading = false
        })
        .catch(err => {
          this.$message({
            message: '同步失败!',
            type: 'error',
          })
          console.log(err)
          this.PageLoading = false
        })
    },
    getProject() {
      this.$api.project
        .listProject({ pageSize: 10000 })
        .then(res => {
          this.projectList = res.data.records
          this.projectList.forEach(v => {
            if (this.customersUnit.indexOf(v.customersUnit) == -1) {
              this.customersUnit.push(v.customersUnit)
            }
          })
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.project
        .deptClassifyManagerNameListNoPage()
        .then(res => {
          this.deptClassifyManagerNameList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    dataUpdate(row) {
      this.$forceUpdate()
    },
    sortChange(column) {
      this.tableData = []
      if (column) {
        const orderMapping = {
          projectPredictStartDate: 'project_Predict_Start_Date',
          projectPredictEndDate: 'project_Predict_End_Date',
          actualPredictStartDate: 'actual_predict_start_date',
          actualAmount: 'actual_amount',
          actualContractAmount: 'actual_contract_amount',
          projectSource: 'project_source',
          businessLines: 'business_lines',
          companyType: 'company_type',
        }

        const orderRuleMapping = {
          ascending: 'ASC',
          descending: 'DESC',
        }

        if (orderMapping[column.prop] && orderRuleMapping[column.order]) {
          this.parameter.order = orderMapping[column.prop]
          this.parameter.orderRule = orderRuleMapping[column.order]

          if (this.ProjectNature != 'ALL') {
            let arr = [...this.produceList, ...this.cooperateList]
            this.ProjectNature.forEach(v => {
              if (arr.indexOf(v) == -1) {
                arr.push(v)
              }
            })
            this.parameter.projectSourceList = [...arr]
          } else {
            this.parameter.projectSourceList = [...this.produceList, ...this.cooperateList]
          }
        } else if (column.order == null) {
          this.parameter.order = null
          this.parameter.orderRule = null
        }
        this.$api.project.listProject(this.parameter).then(res => {
          const arr = res.data.records.map(r => ({
            ...r,
            hasChildren: r.parentId === 0 && r.isHaveChild === true,
            children: [],
          }))

          this.tableData = arr
        })
      }
    },
    refresh(isSaveCurrentPage = false) {
      this.showEditDialog = false
      if (isSaveCurrentPage) {
        this.getData()
      } else {
        this.pageChangeHandler(1)
      }
    },
    detail(row) {
      row.ProjectDisable = 4
      row.isContractInformation = 1
      row.isRecord = true //项目操作记录显示
      this.mode = 'view'
      this.options = row.deepClone()
      this.showEditDialog = true
    },
    edit(row) {
      row.ProjectDisable = 4
      row.isContractInformation = 1
      row.isRecord = true //项目操作记录显示
      this.mode = 'edit'
      this.options = row.deepClone()
      this.showEditDialog = true
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    getData() {
      this.loading = true

      if (this.createdDate && this.createdDate != []) {
        this.parameter.startActualPredictStartDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endActualPredictStartDate = new Date(this.createdDate[1]).getTime()
      } else if (this.createdDate == []) {
        this.parameter.startActualPredictStartDate = null
        this.parameter.endActualPredictStartDate = null
      }
      if (this.ProjectNature != 'ALL') {
        let arr = [...this.produceList, ...this.cooperateList]
        this.ProjectNature.forEach(v => {
          if (arr.indexOf(v) == -1) {
            arr.push(v)
          }
        })
        this.parameter.projectSourceList = [...arr]
      } else {
        this.parameter.projectSourceList = [...this.produceList, ...this.cooperateList]
      }
      this.$api.project
        .listProject(this.parameter)
        .then(res => {
          this.loading = false
          var arr = []
          res.data.records.some(r => {
            arr.push(
              r.combination({
                hasChildren: r.parentId == 0 && r.isHaveChild == true,
                children: [],
              })
            )
          })
          this.tableData = arr
          this.parameter.total = res.data.total
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    load(tree, treeNode, resolve) {
      this.$api.project
        .listProjectNotPage({
          parentId: tree.id,
        })
        .then(res => {
          var arr = []
          res.data.some(r => {
            arr.push(
              r.combination({
                hasChildren: false,
              })
            )
          })
          resolve(arr)
        })
        .catch(err => {
          resolve([])
          console.log(err)
        })
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    // del(scope) {
    //   this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       this.$api.project
    //         .del(scope.row.id)
    //         .then((res) => {
    //           this.$message.success("删除成功！");

    //           var hasLastPage =
    //             this.parameter.pageSize * this.parameter.pageNow >=
    //             this.parameter.total;
    //           var lastData = this.tableData.length < 2;
    //           if (lastData && hasLastPage && this.parameter.pageNow > 1) {
    //             this.parameter.pageNow--;
    //           }
    //           this.getData();
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         });
    //     })
    //     .catch(() => {
    //       //用户取消
    //     });
    // },
    getType() {
      // this.$api.dict
      //   .listSysDictData('PROJECT_TYPE', true)
      //   .then(res => {
      //     this.dictData.projectType = res.data
      //     // PROJECT_TYPE 项目类型
      //     this.ProjectType = initTreeData(res.data)
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
      this.$api.dict
        .listSysDictData('PROJECT_NATURE', true)
        .then(res => {
          const arr = []
          res.data.forEach((v, i) => {
            if (v.dictVal) {
              let str = v.dictVal.split(',')
              arr.push({
                dictName: v.dictName,
                dictVal: str,
              })
              this.projectNatureInfo.push({
                id: i,
                dictName: v.dictName,
                dictVal: str,
              })
            }
          })

          arr.forEach((item, i) => {
            item.dictVal.forEach((value, index) => {
              this.dictData.projectNature.push({
                dictName: item.dictName,
                dictVal: value,
                id: Date.now() + ++index + ++i,
              })
            })
          })
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.dictData.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BUSINESS_LINES', true)
        .then(res => {
          this.dictData.businessLines = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('PROJECT_SOURCE', true)
        .then(res => {
          this.dictData.projectSource = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('PROJECT_STATUS', true)
        .then(res => {
          this.dictData.projectStatus = res.data
          // PROJECT_STATUS 项目阶段状态
          this.StautsType = initTreeData(res.data)
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('PROJECT_NEW_STATUS', true)
        .then(res => {
          this.dictData.projectStatusList = res.data
          this.projectStatus = initTreeData(res.data)
        })
        .catch(err => {
          console.log(err)
        })

      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.deptList = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict.listSysDictData('REGISTRATION_TYPE', true).then(res => {
        this.registrationTypeList = res.data
      })

      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.companyTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })

      this.$api.dict
        .listSysDictData('PROJECT_CATEGORY', true)
        .then(res => {
          this.dictData.projectCategorysList = res.data
          this.projectCategorys = initTreeData(res.data)
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';

/deep/.labelClassName {
  width: 150px;
}
.mainList .mainList_operation {
  margin-bottom: 0;
  padding: 0;
}
/deep/.el-checkbox {
  width: 102px;
  // border: 1px solid #dcdfe6;
  padding: 10px 20px 0 10px;
  border-radius: 4px;
  height: 36px;
  margin-right: 10px;
}
.search_cost_title {
  width: 100%;
  padding-top: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
/deep/ .el-autocomplete-suggestion {
  width: auto !important;
}

/deep/ .more {
  background-color: #aacdee;
  color: #fff;
  padding: 5px 5px;
  margin-left: 8px;
}
.text_Edit_Bgc {
  background-color: #e6a23c;
  color: #fff;
  padding: 5px 5px;
}
.text_Miantan_Bgc {
  background-color: #6ace61c2;
  color: #fff;
  padding: 5px 5px;
}
.text_RESET_PASSWORD_Bgc {
  background-color: #61becec2;
  color: #fff;
  padding: 5px 5px;
}
.mainList .mainList_operation .mainList_operation_search {
  padding-left: 10px;
  .search_center {
    height: auto;
    /deep/.tags-select-input {
      height: 40px;
      width: 180px;
    }
    .tags-select-input /deep/ .el-select__tags {
      height: 40px;
      white-space: nowrap;
      overflow: hidden;
      flex-wrap: nowrap;
    }
    .tags-select-input /deep/ .el-select__tags-text {
      display: inline-block;
      max-width: 55px;
      white-space: nowrap;
      overflow: hidden;
      // text-overflow: ellipsis;
    }
    .tags-select-input /deep/ .el-tag_close.el-icon-close {
      top: -5px;
      right: -4px;
    }
  }
}
</style>
